import React, {
    useRef,
    useState,
    useMemo,
    useCallback,
    useEffect,
} from 'react';

import { BasicButton } from '@nuage/ui-components';

import { dispatchGtagEvent } from '@utils/eventsManager';
import lang from '@utils/lang';
import { randomArray } from '../../helpers';

import Testimony from './testimony';

import './clientTestimony.scss';

const ClientTestimony = () => {
    const { _string } = lang;
    const [sizeWindow, setSizeWindow] = useState(null);
    const [actualPosition, setActualPosition] = useState(0);
    const [offset, setOffset] = useState(0);
    const [mobileOffset, setMobileOffset] = useState(false);
    const [noTransparent, setNotransparent] = useState(false);
    const [displayedCards, setDisplayedCards] = useState(null);

    const [draggable, setDraggable] = useState(false);
    const [isDragged, setDragged] = useState(false);
    const [dragPoint, setDragPoint] = useState(null);

    const sliderTestimony = useRef(null);

    // Basic default format
    const basicSizeTestimony = useMemo(() => 390, []);

    // Textimony params
    const [sizeTestimony, setSizeTestimony] = useState(390);
    const gutter = useMemo(() => 20, []);

    const rawTestimonies = useMemo(() => {
        if (!_string) { return []; }
        // Randomize
        const randomData = randomArray([..._string('testimony_single')]);
        return randomData;
    }, [_string]);

    const blockSize = useMemo(() => (
        (displayedCards * sizeTestimony) + ((displayedCards - 1) * gutter)
    ), [displayedCards, gutter, sizeTestimony]);

    const offsetCards = useMemo(() => {
        if (!displayedCards) { return 0; }
        if (displayedCards % 2 === 0) {
            return (displayedCards - 1) / 2;
        }
        return Math.trunc(displayedCards / 2);
    }, [displayedCards]);

    const preparedTestimonies = useMemo(() => {
        if (!rawTestimonies || rawTestimonies.length === 0 || !displayedCards) { return []; }
        const curatedTestimonies = [];

        const beginPoint = actualPosition - 1;
        const endPoint = actualPosition + displayedCards + 1;

        // Run through displayedElements
        for (let i = beginPoint; i < endPoint; i += 1) {
            const index = i < 0
                ? ((i + rawTestimonies.length) % (rawTestimonies.length))
                : i % (rawTestimonies.length);
            const serie = i < 0
                ? Math.trunc(i - 1 / (rawTestimonies.length - 1))
                : Math.trunc(i / (rawTestimonies.length - 1));
            const rawTestimony = rawTestimonies[Math.abs(index)];
            curatedTestimonies.push({
                ...rawTestimony,
                uniqueId: `${rawTestimony.name}-${serie}`,
                pos: i,
            });
        }
        return curatedTestimonies;
    }, [rawTestimonies, actualPosition, displayedCards]);

    const offsetQuote = useMemo(() => {
        if (noTransparent) {
            return sizeTestimony / 2;
        }
        return blockSize / 2;
    }, [noTransparent, blockSize, sizeTestimony]);

    // This value checks if the screen displays the format in between tablet and mobile
    const onBetweenState = useMemo(
        () => noTransparent && sizeTestimony === basicSizeTestimony,
        [noTransparent, sizeTestimony, basicSizeTestimony],
    );

    const onResize = useCallback(() => {
        if (sliderTestimony?.current) {
            setSizeWindow(sliderTestimony.current?.getBoundingClientRect().width || 0);
        }
    }, []);

    const resetOffset = useCallback(() => {
        setDragged(false);
        setOffset(0);
        setDragPoint(null);
        onResize();
    }, [onResize]);

    const sendGaEvent = useCallback((action) => {
        dispatchGtagEvent(action, {
            event_category: 'slider',
            event_label: 'références',
        });
    }, []);

    const onMouseMove = useCallback((e) => {
        if (isDragged && dragPoint) {
            const posX = e.pageX || e.touches[0]?.pageX;
            if (posX - dragPoint > (sizeTestimony / 2)) {
                resetOffset();
                setActualPosition((prev) => prev - 1);
                sendGaEvent('swipe');
            } else if (posX - dragPoint < -(sizeTestimony / 2)) {
                resetOffset();
                setActualPosition((prev) => prev + 1);
                sendGaEvent('swipe');
            } else {
                setOffset(posX - dragPoint);
            }
        }
    }, [isDragged, dragPoint, resetOffset, sizeTestimony, sendGaEvent]);

    const onTestimonyClicked = useCallback((e) => {
        setDragged(true);
        setDragPoint(e.pageX || e.touches[0]?.pageX);
    }, []);

    useEffect(() => {
        if (window) {
            onResize();
            window.addEventListener('resize', onResize);
        }

        return () => {
            setSizeWindow(null);
            window.removeEventListener('resize', onResize);
        };
    }, [setSizeWindow, onResize]);

    useEffect(() => {
        if (!rawTestimonies || rawTestimonies.length === 0 || !sizeWindow) { return; }
        // 80 is the size of the two buttons
        let maxDisplayable = Math.trunc(
            (sizeWindow - (80 + gutter * 2) - (gutter * 2)) / (basicSizeTestimony + gutter),
        );

        if (maxDisplayable <= 1) {
            setNotransparent(true);
        } else {
            setNotransparent(false);
        }

        // Determine the display type (mobile or desktop) and the testimony card's size
        if (maxDisplayable > 3 || maxDisplayable === 1) {
            setMobileOffset(false);
            maxDisplayable = 3;
            setSizeTestimony(390);
        } else if (maxDisplayable === 0) {
            setSizeTestimony(280);
            maxDisplayable = 3;
            setMobileOffset(true);
        } else {
            setSizeTestimony(390);
            setMobileOffset(false);
        }

        // Determine if draggable
        if (rawTestimonies.length <= maxDisplayable) {
            setDraggable(false);
        } else {
            setDraggable(true);
        }

        // Decide how many element will be displayed
        if (rawTestimonies.length <= maxDisplayable) {
            setDisplayedCards(rawTestimonies.length);
        } else {
            setDisplayedCards(maxDisplayable);
        }
    }, [rawTestimonies, sizeWindow, basicSizeTestimony, gutter]);

    return (
        <div className="client-testimony" onMouseMove={onMouseMove} onTouchMove={onMouseMove}>
            {/* Needed to display testimony for referencing the testimonies */}
            <ul className="client-testimony__refer">
                {
                    _string('testimony_single')?.map(({
                        author,
                        authorRole,
                        text,
                        name,
                    }) => (
                        <li key={name}>
                            <h3>{`${author}, ${authorRole}`}</h3>
                            <p>{_string(text)}</p>
                        </li>
                    ))
                }
            </ul>
            { preparedTestimonies && preparedTestimonies.length > 0 && (
                <p
                    className="client-testimony__quote client-testimony__quote--top"
                    style={{ transform: `translateX(calc(-50% - ${offsetQuote}px))` }}
                >
                    “
                </p>
            )}
            <div
                className={`client-testimony__block${sizeTestimony === 280 ? ' client-testimony__block--small' : ''}`}
                ref={sliderTestimony}
            >
                {draggable && !noTransparent && (
                    <div
                        className="client-testimony__button"
                        style={{ left: `calc(50% - ${offsetQuote}px - 20px - ${gutter}px` }}
                    >
                        <BasicButton
                            className="client-testimony__previous-button"
                            leftIcon="ico-arrow"
                            format="dashed"
                            onClick={() => {
                                setActualPosition((prev) => prev - 1);
                                sendGaEvent('click');
                            }}
                        />
                    </div>
                )}
                {preparedTestimonies.map(({
                    uniqueId,
                    img,
                    name,
                    author,
                    authorRole,
                    text,
                    pos,
                }, id) => (
                    <Testimony
                        name={name}
                        img={img}
                        key={uniqueId}
                        text={_string(text)}
                        author={author}
                        authorRole={authorRole}
                        position={pos - offsetCards}
                        actualPosition={actualPosition}
                        sizeWindow={sizeWindow}
                        onMouseDown={onTestimonyClicked}
                        onMouseUp={resetOffset}
                        offset={offset}
                        isDragged={isDragged}
                        sizeTestimony={sizeTestimony}
                        isFirst={onBetweenState ? id <= 1 : id === 0}
                        isLast={onBetweenState ? id >= displayedCards : id === displayedCards + 1}
                        draggable={draggable}
                        gutter={gutter}
                        noTransparent={noTransparent}
                        mobileOffset={mobileOffset}
                    />
                ))}
                {draggable && !noTransparent && (
                    <div
                        className="client-testimony__button"
                        style={{ left: `calc(50% + ${blockSize / 2}px + 20px + ${gutter}px` }}
                    >
                        <BasicButton
                            className="client-testimony__next-button"
                            leftIcon="ico-arrow"
                            format="dashed"
                            onClick={() => {
                                setActualPosition((prev) => prev + 1);
                                sendGaEvent('click');
                            }}
                        />
                    </div>
                )}
            </div>
            { preparedTestimonies && preparedTestimonies.length > 0 && (
                <p
                    className="client-testimony__quote client-testimony__quote--bottom"
                    style={{ transform: `translateX(calc(50% + ${offsetQuote}px))` }}
                >
                    ”
                </p>
            )}
        </div>
    );
};

export default ClientTestimony;
