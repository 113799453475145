import React, { useState, useEffect, useRef, useCallback } from 'react';

import { BasicButton, A } from '@nuage/ui-components';

import ClientTestimony from '@molecules/group/clientTestimony';
import OxBlob from '@molecules/visual/oxevaBlob';
// import MComplexBlob from '@molecules/interactive/m_complexBlob';

import Yogi from '@images/svg/yogi.svg';
import Rooster from '@images/svg/rooster.svg';

import { dispatchGtagEvent } from '@utils/eventsManager';
import lang from '@utils/lang';
import { usePageContext } from '@organisms/contexts/pageContext';

import MainLayout from '@organisms/main/mainLayout';
import Header from '@organisms/main/header';
import Footer from '@organisms/main/footer';
import SignForm from '@organisms/forms/signForm';
import Part from '@organisms/main/part';
import SwappedContent from '@organisms/utils/swappedContent';
import Group from '@organisms/utils/group';

// Desktop video
import DesktopPoster from '../videos/desktop/home-desktop-poster.png';
import DesktopHVC1 from '../videos/desktop/home-desktop-hvc1.mp4';
import DesktopAVC1 from '../videos/desktop/home-desktop-avc1.mp4';
import DesktopWebm from '../videos/desktop/home-desktop-webm.webm';
// Mobile video
import MobilePoster from '../videos/mobile/home-mobile-poster.png';
import MobileHVC1 from '../videos/mobile/home-mobile-hvc1.mp4';
import MobileAVC1 from '../videos/mobile/home-mobile-avc1.mp4';
import MobileWebm from '../videos/mobile/home-mobile-webm.webm';

import './index.scss';

const IndexPage = () => {
    const { _string } = lang;
    const { setPage } = usePageContext();

    const [binary, setBinary] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [subListOpen, setSubListOpen] = useState(false);
    const [offsetPage, setOffsetPage] = useState(0);

    const cloudServicesElt = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleSubList = (e) => {
        e.preventDefault();
        setSubListOpen(!subListOpen);
    };

    const onScrollIndex = useCallback(() => {
        if (!cloudServicesElt || !setPage) { return; }
        const windowPos = cloudServicesElt.current?.getBoundingClientRect()?.y;
        setPage(windowPos && windowPos > 0 ? 'home' : 'cloudServices');
        if (document) {
            setOffsetPage(document.documentElement.scrollTop);
        }
        setOffsetPage(windowPos);
    }, [setPage]);

    // This is used for force re-render
    // TODO : Is there a better method to update first part when displayed on screen ?
    // (with react-intersection-observer and state, there is a flicker)
    useEffect(() => {
        if (!window) { return; }
        window.addEventListener('scroll', () => {
            setBinary((prev) => !prev);
        });
    }, []);

    useEffect(() => {
        if (!setPage) { return; }
        setPage('home');
    }, [setPage]);

    useEffect(() => {
        if (window) {
            onScrollIndex();
            window.addEventListener('scroll', onScrollIndex);
        }

        return () => {
            setPage(null);
            window.removeEventListener('scroll', onScrollIndex);
        };
    }, [setPage, onScrollIndex]);

    return (
        <MainLayout
            title={_string('intro_titleTab')}
            socials={_string('intro_socials')}
            footer={false}
            classTitle="home"
            overideTitle="Nua.ge – Services cloud"
        >
            <div className="primary_block">
                <div
                    className="slidable"
                >
                    <Header
                        menuOpen={menuOpen}
                        toggleMenu={toggleMenu}
                        toggleSubList={toggleSubList}
                        setSubListOpen={setSubListOpen}
                        isSubListOpen={subListOpen}
                        style={{ zIndex: `${binary ? '3' : '4'}` }}
                    />
                </div>
                <div className="primary_block__content">
                    <div className="primary_block__text">
                        <h1 className="primary_block__title">
                            {_string('intro_title_l1')}
                            <br />
                            {_string('intro_title_l2')}
                        </h1>
                        <p className="primary_block__description"><strong>{_string('intro_desc1')}</strong></p>
                        <div className="primary_block__signin">
                            <SignForm
                                cta={_string('intro_cta')}
                                placeholder={_string('intro_placeholder')}
                            />
                        </div>
                        <p className="primary_block__notice">
                            {`${_string('main_mail_notice')} `}
                            <A
                                className="signup__notice__link"
                                linkProps={{ href: '/mentions-legales#Politique_de_donnees_personnelles' }}
                                color="white"
                            >
                                {_string('main_mail_notice_link')}
                            </A>
                            .
                        </p>
                        <p className="primary_block__description2">{_string('intro_desc2')}</p>
                    </div>
                    <div className="primary_block__content__illu">
                        <Yogi
                            className="primary_block__content__illu__main yogi"
                        />
                    </div>
                </div>
            </div>
            <div className="secondary_block" ref={cloudServicesElt}>
                <div className="slidable">
                    <Header
                        color="white"
                        menuOpen={menuOpen}
                        toggleMenu={toggleMenu}
                        toggleSubList={toggleSubList}
                        isSubListOpen={subListOpen}
                        setSubListOpen={setSubListOpen}
                    />
                </div>
                <Part
                    title={_string('concept_catchphrase')}
                    desc={_string('concept_description')}
                    className="block_anim"
                    anchor="concept"
                    separator={false}
                >
                    <OxBlob
                        color="primary"
                        mainShape="M154.7,69.7c12.9,21.1,17.9,48.1,7.4,68.1s-36.3,33.3-58.6,31.3-41.2-19.2-55.4-41.5S24.3,78,33.7,59,71.5,29.2,96.2,31.4,141.8,48.7,154.7,69.7Z"
                        className="block_anim__blob"
                        offset={offsetPage}
                    />
                    <div className="secondary_block_video">
                        <video className="secondary_block_video--desktop" width="1200" height="600" poster={DesktopPoster} autoPlay muted loop playsInline disablePictureInPicture>
                            <source className="boxShadow" src={DesktopHVC1} type='video/mp4; codecs="hvc1"' />
                            <source className="boxShadow" src={DesktopWebm} type="video/webm" />
                            <source className="boxShadow" src={DesktopAVC1} type='video/mp4; codecs="avc1"' />
                        </video>
                        <video className="secondary_block_video--mobile" width="350" height="336" poster={MobilePoster} autoPlay muted loop playsInline disablePictureInPicture>
                            <source src={MobileHVC1} type='video/mp4; codecs="hevc"' />
                            <source src={MobileWebm} type="video/webm" />
                            <source src={MobileAVC1} type='video/mp4; codecs="avc1"' />
                        </video>
                    </div>
                    <p className="block_anim__text">{_string('concept_description2')}</p>
                    <BasicButton
                        size="large"
                        color="secondary"
                        className="block_anim__button"
                        link={_string('concept_link')}
                        onClick={() => {
                            dispatchGtagEvent('click', {
                                event_category: 'cta',
                                event_label: 'inscription',
                            });
                        }}
                    >
                        {_string('concept_cta')}
                    </BasicButton>
                </Part>
                <Part
                    title={_string('fonct_catchphrase')}
                    desc={_string('fonct_description')}
                    className="block_slider"
                    anchor="fonctionnalites"
                    separator={false}
                >
                    <OxBlob
                        color="primary-hover"
                        mainShape="M167.2,64.3c13.8,20.7,14.5,52,1.1,70.2s-40.8,23.3-66.1,22.1S53.7,147.7,43,131.1s-8.7-42.3,2.9-61.7,32.8-32.6,56.8-34.2S153.5,43.6,167.2,64.3Z"
                        className="block_slider__blob"
                        offset={offsetPage}
                    />
                    <SwappedContent
                        content={_string('fonct_table')}
                        className="block_swapped-content"
                    />
                    <p className="block_slider__text">{_string('fonct_description2')}</p>
                    <BasicButton
                        size="large"
                        color="secondary"
                        className="block_slider__button"
                        link={_string('fonct_link')}
                        onClick={() => {
                            dispatchGtagEvent('click', {
                                event_category: 'cta',
                                event_label: 'tarifs',
                            });
                        }}
                    >
                        {_string('fonct_cta')}
                    </BasicButton>
                </Part>
                <Part
                    title={_string('engag_catchphrase')}
                    desc={_string('engag_description')}
                    className="block_engagement"
                    anchor="engagements"
                    separator
                >
                    <OxBlob
                        color="primary"
                        mainShape="M160,63.2c11.7,22.4,10.9,49.8-1.2,72.9S123.3,178,101,177.4s-43.6-20.5-57.2-44.5S24.3,80.9,35,59.1,72.8,21.6,98.5,22.5,148.4,40.7,160,63.2Z"
                        className="block_engagement__blob"
                        offset={offsetPage}
                    />
                    <Group
                        content={_string('engag_table')}
                        className="block_engagement__content"
                    >
                        <Rooster className="block_engagement__svg" />
                    </Group>

                    <div className="block_engagement__buttons">
                        <div className="block_engagement__buttons__content">
                            <p className="block_engagement__buttons__text">{_string('engag_btn1_desc')}</p>
                            <BasicButton
                                size="large"
                                color="secondary"
                                className="block_engagement__buttons__button"
                                link={_string('engag_btn1_link')}
                                onClick={() => {
                                    dispatchGtagEvent('click', {
                                        event_category: 'cta',
                                        event_label: 'inscription',
                                    });
                                }}
                            >
                                {_string('engag_btn1_cta')}
                            </BasicButton>
                        </div>
                        <div className="block_engagement__buttons__content">
                            <p className="block_engagement__buttons__text">{_string('engag_btn2_desc')}</p>
                            <BasicButton
                                size="large"
                                color="secondary"
                                format="dashed"
                                className="block_engagement__buttons__button"
                                onClick={() => {
                                    if (window.$crisp && !window.$crisp.is('chat:hidden')) {
                                        // dispatchGtagEvent('evt', {
                                        //     event_category: 'cat',
                                        //     event_label: 'cat',
                                        //     value: 'cat',
                                        // });
                                        dispatchGtagEvent('click', {
                                            event_category: 'cta',
                                            event_label: 'chat',
                                        });
                                        window.$crisp.push(['do', 'chat:open']);
                                    } else {
                                        window.location.href = 'mailto:support@nua.ge';
                                    }
                                }}
                            >
                                {_string('engag_btn2_cta')}
                            </BasicButton>
                        </div>
                    </div>
                </Part>
                <Part
                    title={_string('testimony_catchphrase')}
                    className="block_testimony"
                    anchor="testimony"
                    separator={false}
                >
                    <ClientTestimony />
                </Part>
                <Footer />
            </div>
        </MainLayout>
    );
};

export default IndexPage;
