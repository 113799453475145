import React from 'react';
import PropTypes from 'prop-types';

import './swappedContent.scss';

const SwappedContent = ({ content, className }) => {
    const lorem = content.map(({
        title,
        desc,
        image,
        altImage,
    }, id) => {
        const position = id % 2 === 0 ? 'left' : 'right';

        // TODO : Find a better solution ?
        // eslint-disable-next-line import/no-dynamic-require, global-require
        const imgIllu = require(`@images/png/fonctions/${image}`);
        const key = id;
        return (
            <div
                className={`swapped-content__${position}`}
                key={key}
            >
                <div className={`swapped-content__${position}-text`}>
                    <h3 className={`swapped-content__${position}-text__title`}>
                        {title}
                    </h3>
                    <p className={`swapped-content__${position}-text__description`}>
                        {desc}
                    </p>
                </div>
                <div className={`swapped-content__${position}-image`}>
                    <img
                        className={`swapped-content__${position}-alt-image`}
                        alt={altImage}
                        src={imgIllu.default}
                    />
                </div>
            </div>
        );
    });

    return (
        <div className={`swapped-content${className ? ` ${className}` : ''}`}>
            {lorem}
        </div>
    );
};

SwappedContent.propTypes = {
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string,
};

SwappedContent.defaultProps = {
    className: '',
};

export default SwappedContent;
