import React from 'react';
import PropTypes from 'prop-types';

import './group.scss';

const Group = ({ content, className, children }) => {
    const texts = [];

    for (let i = 0; i < content.length; i += 1) {
        const elt = content[i];
        texts.push(
            <li key={i} className="group__elt">
                <h3 className="group__title">{elt.title}</h3>
                <div className="group__text"><p>{elt.desc}</p></div>
            </li>,
        );
    }

    return (
        <div className={`group${className ? ` ${className}` : ''}`}>
            {children}
            <div className="group__content">
                <ul>
                    {texts}
                </ul>
            </div>
        </div>
    );
};

Group.propTypes = {
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
};

Group.defaultProps = {
    children: null,
    className: null,
};

export default Group;
