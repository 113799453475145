import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Nuage from '@oxeva/nuage-sdk';

import { BasicField, ComplexButton, BasicIcon } from '@nuage/ui-components';

import { dispatchGtagEvent } from '@utils/eventsManager';
import lang from '@utils/lang';
import NuageClient from '@utils/client';

import './signForm.scss';

const SignForm = ({ cta, placeholder }) => {
    const { _string } = lang;

    const [clObj, setClObj] = useState(null);
    const [mail, setMail] = useState('');
    const [stateBtn, setStateBtn] = useState('initial');
    const [errorVisible, setErrorVisible] = useState(false);
    const [validVisible, setValidVisible] = useState(false);
    const [errorType, setErrorType] = useState(null);
    const [btnDisable, setBtnDisable] = useState(false);

    const timer = useRef(null);

    const onSignin = useCallback(() => {
        if (!clObj) { return; }
        const reg = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

        clearTimeout(timer.current);

        if (mail.match(reg)) {
            setStateBtn('loading');
            setBtnDisable(true);

            const invitation = new Nuage.Invitation({
                email: mail,
                coupon: process.env.COUPON_DEFAULT,
            });
            clObj.persist(invitation).onReady((error) => {
                if (error) {
                    setErrorVisible(true);
                    setStateBtn('initial');
                    switch (error.status) {
                    case 429: {
                        setErrorType('requestError');
                        timer.current = setTimeout(() => {
                            setErrorVisible(false);
                            setBtnDisable(false);
                        }, 3000);
                        break;
                    }
                    case 422: {
                        setErrorType('forbidden');
                        timer.current = setTimeout(() => {
                            setErrorVisible(false);
                            setBtnDisable(false);
                        }, 3000);
                        break;
                    }
                    default: {
                        setErrorType('alert');
                        timer.current = setTimeout(() => {
                            setErrorVisible(false);
                        }, 3000);
                        setBtnDisable(false);
                        break;
                    }
                    }
                } else {
                    setValidVisible(true);
                    dispatchGtagEvent('submit', {
                        event_category: 'form',
                        event_label: 'compte',
                    });
                }
                setStateBtn('initial');
                setBtnDisable(false);
            });
        } else {
            setErrorVisible(true);
            setErrorType('error');
            timer.current = setTimeout(() => { setErrorVisible(false); }, 3000);
        }
    }, [clObj, mail]);

    const onChangeMail = (e) => {
        setMail(e.target.value);
    };

    const classMailName = useMemo(() => {
        let classString = 'signForm__field';

        if (errorVisible) {
            classString += errorType === 'error' ? ' a-field--error' : ' a-field--alert';
        }
        return classString;
    }, [errorVisible, errorType]);

    useEffect(() => {
        const newClObj = new NuageClient();
        setClObj(newClObj.cl);
    }, []);

    return (
        <form className="signForm" onSubmit={(e) => { e.preventDefault(); onSignin(); }}>
            <div className="signForm__form">
                <BasicField
                    id="sign_mail"
                    name="sign_mail"
                    className={classMailName}
                    onChange={onChangeMail}
                    value={mail}
                    placeholder={placeholder}
                    disabled={stateBtn === 'loading'}
                />
                <ComplexButton
                    onClick={onSignin}
                    disabled={stateBtn === 'loading' || btnDisable}
                    color="secondary"
                    loading={stateBtn === 'loading'}
                    size="large"
                >
                    {cta}
                </ComplexButton>
            </div>
            <div className={`signForm__errorWindow${errorVisible ? ' signForm__errorWindow--visible' : ''}`}>
                <div className="signForm__errorWindow__content">
                    <BasicIcon
                        name={errorType === 'error' || errorType === 'forbidden' || errorType === 'requestError' ? 'ico-close' : 'ico-exclamation'}
                        boxColor={errorType === 'error' || errorType === 'forbidden' || errorType === 'requestError' ? 'error' : 'alert'}
                        size="small"
                        color="white"
                    />
                    <p>{_string(`intro_${errorType}`)}</p>
                </div>
            </div>
            <div className={`signForm__validWindow${validVisible ? ' signForm__validWindow--visible' : ''}`}>
                <div className="signForm__validWindow__content">
                    <BasicIcon
                        name="ico-check"
                        boxColor="valid"
                        size="small"
                        color="white"
                    />
                    <p>{_string('intro_valid', { mail })}</p>
                </div>
            </div>
        </form>
    );
};

SignForm.propTypes = {
    cta: PropTypes.string,
    placeholder: PropTypes.string,
};

SignForm.defaultProps = {
    cta: '',
    placeholder: '',
};

export default SignForm;
