import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './testimony.scss';

const Testimony = ({
    name,
    img,
    text,
    author,
    authorRole,
    position,
    actualPosition,
    offset,
    sizeWindow,
    onMouseUp,
    onMouseDown,
    isDragged,
    sizeTestimony,
    gutter,
    isFirst,
    isLast,
    draggable,
    noTransparent,
    mobileOffset,
}) => {
    // TODO : Find a better solution ?
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const imgCompany = require(`@images/png/company/${img}.png`);

    const initialPos = useMemo(() => {
        if (mobileOffset) {
            return 0;
        }
        return (sizeWindow / 2) - (sizeTestimony / 2);
    }, [sizeWindow, sizeTestimony, mobileOffset]);

    const pos = useMemo(() => {
        if (position === null || actualPosition === null) { return null; }
        return ((position - actualPosition) * (sizeTestimony + gutter)) + offset + initialPos;
    }, [
        gutter,
        sizeTestimony,
        actualPosition,
        position,
        offset,
        initialPos,
    ]);

    const opacity = useMemo(() => {
        if (isDragged && !noTransparent
            && ((isFirst && offset > 0) || (isLast && offset < 0))) {
            return Math.abs(offset / sizeTestimony);
        }
        if ((isFirst || isLast) && !noTransparent) {
            return 0;
        }
        if (noTransparent && (isFirst || isLast)) {
            return 0.6;
        }
        return 1;
    }, [
        isDragged,
        isFirst,
        isLast,
        sizeTestimony,
        offset,
        noTransparent,
    ]);

    return (
        <div
            className={`
            testimony
                ${pos !== null ? 'testimony--visible' : ''}
                ${isDragged ? ' testimony--dragged' : ''}
                ${!draggable ? ' testimony--nodrag' : ''}`
                .replace(/\s+/g, ' ')
                .trim()}
            style={{ left: `${pos || 0}px`, width: `${sizeTestimony || 0}px`, opacity }}
            onMouseUp={draggable ? onMouseUp : null}
            onMouseDown={draggable ? onMouseDown : null}
            onKeyPress={draggable ? onMouseDown : null}
            onTouchStart={draggable ? onMouseDown : null}
            onTouchEnd={draggable ? onMouseUp : null}
            role="menuitem"
            tabIndex="0"
        >
            <p className="testimony__text">{ text }</p>
            <div className="testimony__details">
                <img src={imgCompany.default} className="testimony__logo" alt={name} />
                <div className="testimony__author">
                    <p className="testimony__author__name">{author}</p>
                    <p className="testimony__author__role">{authorRole}</p>
                </div>
            </div>
        </div>
    );
};

Testimony.propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    authorRole: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    position: PropTypes.number,
    actualPosition: PropTypes.number.isRequired,
    offset: PropTypes.number,
    sizeWindow: PropTypes.number,
    sizeTestimony: PropTypes.number,
    gutter: PropTypes.number,
    onMouseUp: PropTypes.func,
    onMouseDown: PropTypes.func,
    isDragged: PropTypes.bool,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    draggable: PropTypes.bool,
    noTransparent: PropTypes.bool,
    mobileOffset: PropTypes.bool,
};

Testimony.defaultProps = {
    position: null,
    offset: 0,
    sizeWindow: 0,
    sizeTestimony: 390,
    gutter: 20,
    onMouseUp: () => {},
    onMouseDown: () => {},
    isDragged: false,
    isFirst: false,
    isLast: false,
    draggable: true,
    noTransparent: false,
    mobileOffset: false,
};

export default Testimony;
